import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { FaSignature, FaHome, FaBirthdayCake, FaGraduationCap } from 'react-icons/fa';
import './style.css';
export default class GeneralInformation extends React.Component {
  render (){
    var birthday = new Date('July 27, 1989');
    var age = Math.abs(Math.floor((((new Date() - birthday) / 1000) / (60*60*24))/365.25));
    return (
      <div className="general-information bg-dark text-light rounded-bottom">
        <h6 className="pt-2 text-center">General Information</h6>
        <ListGroup  flush >
          <ListGroupItem color="dark"><FaSignature /> Name:<span className="float-right">Michael Link</span></ListGroupItem>
          <ListGroupItem color="dark"><FaHome /> Location:<span className="float-right">Omaha, NE</span></ListGroupItem>
          <ListGroupItem color="dark"><FaBirthdayCake /> Age:<span className="float-right">{age} years</span></ListGroupItem>
          <ListGroupItem color="dark" className=""><FaGraduationCap/> Education:<span className="float-right">Bachelors</span></ListGroupItem>
        </ListGroup>
      </div>
    );
  }
}