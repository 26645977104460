import React from 'react';
import {
  //Collapse,
  Navbar,
  //NavbarToggler,
  NavbarBrand,
  //Nav,
  //NavItem,
  //NavLink 
  } from 'reactstrap';
import './style.css';

export default class Navigation extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    return (
      <Navbar className="rounded" color="dark" light expand="md">
        <NavbarBrand className="text-light" href="/">Michael<span className="text-primary">.</span>Link</NavbarBrand>
      </Navbar>
    );
  }
}