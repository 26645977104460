import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import * as Portrait from './Assets/Images/Portrait.png';
import GeneralInformation from './Components/GeneralInformation';
import './style.css';

export default class Content extends React.Component {
  render() {
    return (
      <Container>
        <Row className="no-gutters">
          <Col lg="3" className="d-none d-lg-block" id="content-sidebar">
            <img src={Portrait} alt="Michael Link" className="portrait rounded-top" />
            <GeneralInformation />
          </Col>
          <Col md="12" lg="9" id="content-section" className="px-4 pb-1 bg-light my-3 rounded">
            <Container className="no-gutters pt-4" fluid>
              <Row >
                <Col md="4" className="d-none d-md-block d-lg-none">
                  <img src={Portrait} alt="Michael Link" className="portrait rounded" />
                </Col>
                <Col md="8" className="d-none d-md-block d-lg-none">
                  <GeneralInformation className=""/>
                </Col>
                <Col md="12" className="d-none d-md-block d-lg-none">
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col>
                
                  
                  
                  <h4 className="text-muted pb-0 mb-0">Full Stack Developer</h4>
                  <h1 className="pt-0 mt-0">About Michael Link</h1>
                  
                  <h3>Description</h3>
                  <p>Graduating from the University of Nebraska at Omaha with a Bachelors in Computer Science, Michael fufilled his dream. Since programming his first software at the age of 9, he knew exactly what he wanted to do.</p>
                  <p>He was able to land an internship with the university as a software developer. Within a few months he was hired on as a fulltime developer. Since then he has created and maintained many web applications including the front end for the Student Information System.</p>
                  <p>Today, he continues to work for the university and takes on small clients for web development. If you have a project and in need of a developer, then feel free to contact him.</p> 
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}