import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Navigation from './Components/Navigation';
import Content from './Components/Content';
import './App.css';


class App extends Component {
  render() {
    return (
      <Container className="App" color="dark">
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <Row className="mt-md-4">
          <Col size="12">
            <Navigation />
          </Col>
        </Row>
        <Row id="main-content">
          <Col size="12">
            <Content />
          </Col>
        </Row>
        
      </Container>
    );
  }
}

export default App;
